"use client";
import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import { NewsArticle, Song } from "./types/types";
import {
  motion,
  AnimatePresence,
  useScroll,
  useTransform,
  useSpring,
  useInView,
} from "framer-motion";

interface ClientHomePageProps {
  initialSongs: Song[];
  initialNewsArticles: NewsArticle[];
}

const preventScroll = (e: WheelEvent | TouchEvent) => {
  e.preventDefault();
};

const ClientHomePage: React.FC<ClientHomePageProps> = ({
  initialSongs,
  initialNewsArticles,
}) => {
  const [musicCatalog, setMusicCatalog] = useState<Song[]>(initialSongs);
  const [newsArticles, setNewsArticles] =
    useState<NewsArticle[]>(initialNewsArticles);
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const headerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start start", "end end"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.8]);
  const y = useTransform(scrollYProgress, [0, 0.5], [0, -50]);

  useEffect(() => {
    setIsMounted(true);
    const measureHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    measureHeaderHeight();
    window.addEventListener("resize", measureHeaderHeight);

    setTimeout(() => {
      setIsLoading(false);
    }, 2500);

    return () => {
      window.removeEventListener("resize", measureHeaderHeight);
    };
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    const musicInterval = setInterval(() => {
      if (!isHovering) {
        setCurrentMusicIndex(
          (prevIndex) => (prevIndex + 1) % musicCatalog.length
        );
      }
    }, 8000);

    return () => {
      clearInterval(musicInterval);
    };
  }, [musicCatalog.length, isHovering, isMounted]);

  const handlePrevSong = () => {
    setCurrentMusicIndex(
      (prevIndex) => (prevIndex - 1 + musicCatalog.length) % musicCatalog.length
    );
  };
  useEffect(() => {
    // Prevent scrolling
    document.body.style.overflow = "hidden";
    window.addEventListener("wheel", preventScroll, { passive: false });
    window.addEventListener("touchmove", preventScroll, { passive: false });

    // Re-enable scrolling after 3 seconds
    const timer = setTimeout(() => {
      document.body.style.overflow = "visible";
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("touchmove", preventScroll);
    }, 3000);

    return () => {
      clearTimeout(timer);
      document.body.style.overflow = "visible";
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("touchmove", preventScroll);
    };
  }, []);

  const handleNextSong = () => {
    setCurrentMusicIndex((prevIndex) => (prevIndex + 1) % musicCatalog.length);
  };

  const getVisibleSongs = () => {
    const visibleSongs = [];
    for (let i = -1; i <= 1; i++) {
      const index =
        (currentMusicIndex + i + musicCatalog.length) % musicCatalog.length;
      visibleSongs.push(musicCatalog[index]);
    }
    return visibleSongs;
  };

  const convertToHtml = (content: string) => {
    // Implement your HTML conversion logic here
    return content;
  };

  const letterVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.5,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    }),
  };

  const loadingContainerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 1,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const pageContentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const FadeInWhenVisible: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 50 },
        }}
        transition={{ duration: 0.6 }}
      >
        {children}
      </motion.div>
    );
  };

  return (
    <div className="relative" ref={scrollRef}>
      <AnimatePresence>
        {isLoading && (
          <motion.div
            key="loading"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={loadingContainerVariants}
            className="fixed inset-0 bg-black flex items-center justify-center z-50"
          >
            <motion.div className="relative">
              <motion.h1 className="text-3xl sm:text-7xl font-thin text-white tracking-wider font-serif">
                {["John", "Danziger"].map((word, wordIndex) => (
                  <React.Fragment key={wordIndex}>
                    {wordIndex > 0 && " "}
                    {word.split("").map((char, charIndex) => (
                      <motion.span
                        key={`${char}-${wordIndex}-${charIndex}`}
                        variants={letterVariants}
                        custom={charIndex + wordIndex * 5}
                        style={{ display: "inline-block" }}
                      >
                        {char}
                      </motion.span>
                    ))}
                  </React.Fragment>
                ))}
              </motion.h1>

              <motion.div
                className="h-[3px] my-3 bg-white"
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{
                  delay: 1.5,
                  duration: 0.8,
                  ease: [0.6, -0.05, 0.01, 0.99],
                }}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      

      <motion.div
        initial="hidden"
        animate={isLoading ? "hidden" : "visible"}
        variants={pageContentVariants}
        className="font-goodfont"
      >
        {/* Hero Section */}
        <motion.section
          className="relative"
          style={{
            height: `calc(100vh - ${headerHeight}px - 6rem + 50px)`,
            opacity,
            scale,
            y,
          }}
        >
          <div
            className="absolute inset-0 bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: "url(/Hero.png)",
              backgroundPosition: "center 30%",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="absolute inset-0 z-20 bg-black opacity-[.45]"></div>
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
          <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-transparent"></div>
          <div className="absolute inset-0 bg-gradient-to-l from-black via-transparent to-transparent"></div>
          <div className="relative z-[25] flex items-end justify-center h-full pb-10 sm:pb-20">
            <div className="text-center px-4">
              <motion.h1
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.8 }}
                className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4"
              >
                John Danziger
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7, duration: 0.8 }}
                className="text-lg sm:text-xl md:text-2xl text-white mb-6 sm:mb-8"
              >
                Emerging Composer
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.9, duration: 0.8 }}
              >
                <Link
                  href="/music"
                  className="inline-block px-4 sm:px-6 py-2 sm:py-3 text-white bg-transparent border border-white rounded-none hover:bg-white hover:text-black transition-colors duration-500 cursor-pointer text-sm sm:text-base"
                >
                  Explore Music
                </Link>
              </motion.div>
            </div>
          </div>
        </motion.section>

{/* Music Catalog */}
{isMounted && musicCatalog.length > 0 && (
  <motion.section 
    className="py-10 sm:py-16"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true, amount: 0.5 }}
    transition={{ duration: 2.5 }}
  >
    <div className="container mx-auto px-4">
      <h2 className="text-3xl sm:text-4xl text-white mb-6 sm:mb-8 text-center uppercase tracking-widest">
        Music Catalog
      </h2>
      <hr className="border-gray-600 mt-4 sm:mt-5 mb-6 sm:mb-9 w-4/5 sm:w-2/6 m-auto" />
      <div className="flex justify-center items-center space-x-2 sm:space-x-4">
        <button
          onClick={handlePrevSong}
          className="text-white text-2xl sm:text-3xl focus:outline-none transition-transform duration-500 hover:scale-110"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          &lt;
        </button>
        <div className="flex space-x-2 sm:space-x-4">
          {getVisibleSongs().map((song, index) => (
            <Link
              key={song.id}
              href={`/music/${song.id}`}
              className={`w-1/3 bg-black rounded-none overflow-hidden border border-white transform transition-all duration-500 cursor-pointer ${
                index === 1 ? 'scale-100 hover:scale-110' : 'scale-75 opacity-50 hover:scale-90 hover:opacity-95'
              }`}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <div className="w-full aspect-square overflow-hidden">
                <Image
                  src={song.coverArt||''}
                  alt={song.title}
                  width={300}
                  height={300}
                  className="w-full h-full object-cover transition-transform duration-500"
                />
              </div>
              <div className="p-2 sm:p-4">
                <h3 className="text-sm sm:text-lg text-white mb-1 sm:mb-2 uppercase tracking-wider">
                  {song.title}
                </h3>
                <p className="text-xs sm:text-sm text-gray-400">
                  Instrumentation: {song.instrumentation}
                </p>
                <p className="text-xs sm:text-sm text-gray-400">
                  Duration: {song.duration} minutes
                </p>
              </div>
            </Link>
          ))}
        </div>
        <button
          onClick={handleNextSong}
          className="text-white text-2xl sm:text-3xl focus:outline-none transition-transform duration-500 hover:scale-110"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          &gt;
        </button>
      </div>
    </div>
  </motion.section>
)}
        {/* Latest News */}
        {isMounted && newsArticles.length > 0 && (
          <motion.section
            className="py-10 sm:py-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <div className="container mx-auto px-4">
              <motion.h2
                className="text-3xl sm:text-4xl text-white mb-6 sm:mb-8 text-center uppercase tracking-widest"
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                Latest News
              </motion.h2>
              <motion.hr
                className="border-gray-600 mt-4 sm:mt-5 mb-6 sm:mb-9 w-4/5 sm:w-2/6 m-auto"
                initial={{ scaleX: 0 }}
                whileInView={{ scaleX: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: 0.2 }}
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-8">
                {newsArticles.map((article, index) => (
                  <motion.div
                    key={article.id}
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <Link href={`/news/${article.id}`} className="block">
                      <motion.div
                        className="bg-black rounded-none overflow-hidden border border-white transform transition-all duration-500 hover:scale-95 cursor-pointer"
                        whileHover={{ scale: 0.95 }}
                      >
                        <div className="p-4">
                          <h2 className="text-xl sm:text-2xl text-white mb-2">
                            {article.headline}
                          </h2>
                          <p className="text-base sm:text-lg text-gray-400 mb-4">
                            {new Date(article.date).toLocaleDateString()}
                          </p>
                          <div
                            className="text-white mb-4 line-clamp-3 text-sm sm:text-base"
                            dangerouslySetInnerHTML={{
                              __html: convertToHtml(article.bodyContent),
                            }}
                          />
                        </div>
                      </motion.div>
                    </Link>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.section>
        )}

        {/* Call-to-Action */}
        <motion.section
          className="py-10 sm:py-16"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <div className="container mx-auto px-4 text-center">
            <motion.h2
              className="text-3xl sm:text-4xl text-white mb-6 sm:mb-8"
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Explore More
            </motion.h2>
            <motion.p
              className="text-lg sm:text-xl text-gray-400 mb-6 sm:mb-8"
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              Discover the captivating compositions of John Danziger
            </motion.p>
            <motion.div
              className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-8"
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link
                  href="/about"
                  className="inline-block px-6 sm:px-8 py-2 sm:py-3 text-white bg-transparent border border-white rounded-none hover:bg-white hover:text-black transition-colors duration-500 hover: cursor-pointer text-sm sm:text-base"
                >
                  About
                </Link>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link
                  href="/music"
                  className="inline-block px-6 sm:px-8 py-2 sm:py-3 text-white bg-transparent border border-white rounded-none hover:bg-white hover:text-black transition-colors duration-500 cursor-pointer text-sm sm:text-base"
                >
                  Browse Catalog
                </Link>
              </motion.div>
            </motion.div>
          </div>
        </motion.section>
      </motion.div>
    </div>
  );
};

export default ClientHomePage;
